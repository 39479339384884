<template>
	<ConfirmDialog></ConfirmDialog>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Cajas</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Listado de Cajas</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Cajas</div>
				<div class="flex align-items-center text-700 flex-wrap">
					<div class="mr-5 flex align-items-center mt-0">
						<i class="pi pi-briefcase mr-2"></i>
						<span>{{ totalRegistros }} Cajas</span>
					</div>
				</div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Aperturar Caja"
					v-if="'Caja Crear' in auth.user.permissions"
					class="p-button-outlined mr-2"
					icon="pi pi-plus"
					v-tooltip.top="'Aperturar Nueva Caja'"
					@click="NuevoCajaApertura"
				>
				</Button>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtcajas"
				dataKey="id"
				:value="cajas"
				:paginator="true"
				:loading="cargando"
				:rows="10"
				:rowHover="true"
				class="p-datatable-sm"
				stripedRows
        showGridlines
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 25]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Cajas"
				responsiveLayout="scroll"
			>
				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty> <span class="flex align-items-center justify-content-center p-invalid">No existen Registros!</span></template>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>

				<Column field="id" header="ID" :sortable="true">
					{{ data.id }}</Column
				>
				<Column field="nombre_user" header="USUARIO"> {{ nombre_user }}</Column>
				<Column field="nombre_sucursal" header="SUCURSAL">
					{{ nombre_sucursal }}</Column
				>
				<Column field="saldo_inicial" header="SALDO INICIAL" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.saldo_inicial) }}
					</template>
				</Column>
				<Column
					field="calculo_total_ingresos"
					header="TOTAL INGRESOS"
					class="text-right"
				>
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.calculo_total_ingresos) }}
					</template>
				</Column>
				<Column
					field="calculo_total_egresos"
					header="TOTAL EGRESOS"
					class="text-right"
				>
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.calculo_total_egresos) }}
					</template>
				</Column>
				<Column
					field="calculo_total_ventas"
					header="TOTAL VENTAS"
					class="text-right"
				>
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.calculo_total_ventas) }}
					</template>
				</Column>
				<Column
					field="monto_entregar"
					header="MONTO ENTREGAR"
					class="text-right"
				>
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.monto_entregar) }}
					</template>
				</Column>
				<Column
					field="calculo_total_saldo_caja"
					header="SALDO CAJA"
					class="text-right"
				>
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.calculo_total_saldo_caja) }}
					</template>
				</Column>
				<Column field="fecha_apertura" header="FECHA APERTURA">
					<template #body="{ data }">
						{{ data.fecha_apertura }}
					</template>
				</Column>
				<Column field="fecha_cierre" header="FECHA CIERRE">
					<template #body="{ data }">
						{{ data.fecha_cierre }}
					</template>
				</Column>

				<Column field="estado" header="ESTADO">
					<template #body="{ data }">
						<span :class="'product-badge ' + claseEstado[data.estado]">
							{{ data.estado_texto }}
						</span>
					</template>
				</Column>

				<Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
			<CajaApertura
				:show="cajaAperturaDialog"
				:caja="{ ...caja }"
				@closeModal="cerrarModalCajaApertura"
				@actualizarListado="cargarCajas"
			/>
			<CajaCierre
				:show="cajaCierreDialog"
				:cajacierre="caja"
				@closeModal="cerrarModalCajaCierre"
				@actualizarListado="cargarCajas"
			/>
			<MovCaja
				:show="mov_cajaDialog"
				:mov_caja="caja"
				@closeModal="cerrarModalMovCaja"
				@actualizarListado="cargarCajas"
			/>
		</div>
	</div>
</template>

<script>
import CajaApertura from "@/module/cajas/CajaApertura.vue";
import CajaCierre from "@/module/cajas/CajaCierre.vue";
import CajaService from "@/service/CajaService";
import MovCaja from "@/module/cajas/MovCaja.vue";
import { useAuth } from "@/stores";
export default {
	components: {
		CajaApertura,
		CajaCierre,
		MovCaja,
	},

	data() {
		return {
			cargando: true,
			cajas: null,
			cajaAperturaDialog: false,
			cajaCierreDialog: false,
			mov_cajaDialog: false,
			caja: {},
			mov_caja: {},
			cajacierre: {},
			totalRegistros: 0,
			claseEstado: ["status-instock", "status-instock", " status-outofstock"],
			saldo_anterior: 0,
		};
	},
	cajaService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.cajaService = new CajaService();
	},
	mounted() {
		this.cargarCajas();
		this.getSaldoAnterior();
	},
	methods: {
		getSaldoAnterior() {
			this.cajaService.getSaldoAnterior().then((response) => {
				this.saldo_anterior = response.saldo_anterior;
			});
		},
		acciones(datos) {
			return [
				{
					label: "Ingreso/Egreso",
					icon: "pi pi-money-bill",
					disabled: datos.estado != 1 ? true : false,
					command: () => {
						this.nuevoMovCaja(datos);
					},
				},
				{
					label: "Cerrar Caja",
					disabled: datos.estado != 1 ? true : false,
					visible: "Caja Cerrar" in this.auth.user.permissions ? true : false,
					icon: "pi pi-lock",
					command: () => {
						this.NuevoCerrarCaja(datos);
					},
				},
				{
					label: "Descargar PDF",
					icon: "pi pi-fw pi-file-pdf",
					disabled:
						"Caja DescargarPDF" in this.auth.user.permissions ? false : true,
					command: () => {
						this.imprimirCaja(datos.id);
					},
				},
				{
					label: "Detalle",
					disabled:
						"Caja Ver Detalle" in this.auth.user.permissions ? false : true,
					icon: "pi pi-fw pi-list",
					to: "caja/" + datos.id + "/detalle",
				},
				{
					label: "Eliminar",
					disabled: datos.estado == 1 ? true : false || "Caja Eliminar" in this.auth.user.permissions ? false : true,
					icon: "pi pi-fw pi-trash",
					command: () => {
						this.eliminarCaja(datos.id);
					},
				},
			];
		},
		nuevoMovCaja(caja) {
			this.caja = { ...caja };
			this.mov_cajaDialog = true;
		},
		cerrarModalMovCaja() {
			this.mov_cajaDialog = false;
			this.caja = {};
		},
		eliminarCaja(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea eliminar la caja?",
				header: "Confirmación",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo eliminar",
				rejectLabel: "No, cancelar",
				accept: () => {
					this.cajaService
						.eliminarCaja(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargarCajas();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
		convertirNumeroGermanicFormat(numero) {
			return new Intl.NumberFormat("de-DE", {
				minimumFractionDigits: 2,
			}).format(numero);
		},
		imprimirCaja(id) {
			this.cajaService
				.imprimirCaja(id)
				.then((data) => {
					console.log(data);
				})
				.catch((error) => console.log(error));
			console.log(id);
		},
		cargarCajas() {
			this.cajaService.getCajasAll().then((data) => {
				this.cajas = data.cajas;
				this.cargando = false;
			});
			this.getSaldoAnterior();
		},
		NuevoCajaApertura() {
			this.cajaAperturaDialog = true;
			this.caja = {
				estado: { label: "Abierto", value: 1 },
				fecha_cierre: null,
				saldo_inicial: parseFloat(this.saldo_anterior),
			};
		},
		cerrarModalCajaApertura() {
			this.cajaAperturaDialog = false;
		},
		NuevoCerrarCaja(caja) {
			this.caja = { ...caja };
			this.cajaCierreDialog = true;
		},
		cerrarModalCajaCierre() {
			this.cajaCierreDialog = false;
		},
		cerrarCaja(caja) {
			this.errors = {};
			this.caja = { ...caja };
			this.cajaCierreDialog = true;
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.cajas.length; i++) {
				if (this.cajas[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		formatDate(value) {
			return value.toLocaleDateString("es-Es", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			});
		},
	},
	watch: {
		cajas() {
			this.totalRegistros = this.cajas.length;
		},
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
