<template>
	<Toast />
	<Dialog
		v-model:visible="mostrarModal"
		:style="{ width: '750px' }"
		:header="'CIERRE DE CAJA: #' + data_cajacierre.id"
		:modal="true"
		@hide="ocultarModal"
		class="p-fluid"
	>
		<div class="col-12">
			<Divider align="left">
				<div class="inline-flex align-items-center">
					<i class="pi pi-unlock"></i>
					<b>DATOS DE APERTURA</b>
				</div>
			</Divider>
			<div class="card p-fondo">
				<div class="p-fluid formgrid grid p-fondo">
					<div class="field col-12 md:col-6">
						<label for=""
							><strong>USUARIO : </strong
							>{{ data_cajacierre.nombre_user }}</label
						>
					</div>
					<div class="field col-12 md:col-6">
						<label for=""
							><strong>SUCURSAL : </strong
							>{{ data_cajacierre.nombre_sucursal }}</label
						>
					</div>
					<div class="field col-12 md:col-6">
						<strong> MONTO INICIAL : </strong
						>{{ convertirNumeroGermanicFormat(data_cajacierre.saldo_inicial) }}
						Bs
					</div>
					<div class="field col-12 md:col-6">
						<label for=""
							><strong>FECHA APERTURA:</strong>
							{{ data_cajacierre.fecha_apertura }}</label
						>
					</div>
				</div>
			</div>
			<Divider align="left">
				<div class="inline-flex align-items-center">
					<i class="pi pi-lock"></i>
					<b>DATOS DE CIERRE</b>
				</div>
			</Divider>
			<div class="card p-fondo2">
				<div class="p-fluid formgrid grid">
					<div class="field col-12 md:col-4">
						<strong> TOTAL VENTAS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ventas
							)
						}}
					</div>
					<div class="field col-12 md:col-4">
						<strong>VENTAS EFECTIVO : </strong >{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ventas_efectivo
							)
						}}
					</div>
					<!-- <div class="field col-12 md:col-4">
						<strong>TRANSFERENCIAS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ventas_metodos_pago
							)
						}}
					</div> -->
					<div v-if="data_cajacierre.detalle_suma_cobros_x_metodos_pago.length > 0">
						<div
							v-for="(item, index) in data_cajacierre.detalle_suma_cobros_x_metodos_pago"
							:key="index"
							class="field col-12 md:col-4"
						>
							<strong>{{ item.nombre }} : </strong
							>{{ convertirNumeroGermanicFormat(item.total) }}
						</div>
					</div>
					<div class="field col-12 md:col-4">
						<strong> TOTAL INGRESOS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ingresos
							)
						}}
					</div>
					<div class="field col-12 md:col-4">
						<strong> TOTAL EGRESOS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_egresos
							)
						}}
					</div>
					<div class="field col-12 md:col-4">
						<strong> DIFERENCIA : </strong
						>{{ convertirNumeroGermanicFormat(diferencia) }}
					</div>
					<div class="field col-12 md:col-4">
						<strong> SALDO EN CAJA : </strong
						>{{ convertirNumeroGermanicFormat(saldo_caja - diferencia) }}
					</div>
				</div>
			</div>
			<Divider align="left">
				<div class="inline-flex align-items-center">
					<i class="pi pi-cog"></i>
					<b>OPERACIONES</b>
				</div>
			</Divider>
			<div class="p-fluid formgrid grid">
				<table class="field col-12 md:col-12">
					<thead>
						<tr>
							<td><strong>FECHA DE CIERRE:</strong></td>
							<td>
								<Calendar
									v-model="data_cajacierre.fecha_cierre"
									:showIcon="true"
									dateFormat="dd/mm/yy"
								/>
							</td>
						</tr>
						<tr>
							<td>
								<strong
									>RECUENTO EFECTIVO: <small class="p-invalid">*</small></strong
								>
							</td>
							<td>
								<InputNumber
									id="recuento_efectivo"
									inputClass="text-right"
									:showButtons="true"
									autofocus
									v-model="data_cajacierre.recuento_efectivo"
									:minFractionDigits="2"
									locale="de-DE"
								/>
							</td>
						</tr>
						<tr>
							<td><strong>MONTO A ENTREGAR:</strong></td>
							<td>
								<InputNumber
									v-model="data_cajacierre.monto_entregar"
									:minFractionDigits="2"
									:showButtons="true"
                  model="decimal"
									locale="de-DE"
									inputClass="text-right"
								/>
							</td>
						</tr>
						<tr>
							<td>
								<strong>OBSERVACIÓN: </strong>
							</td>
							<td>
								<Textarea v-model="data_cajacierre.observacion" />
								<small class="p-invalid" v-if="errors.observacion">{{
									errors.observacion[0]
								}}</small>
							</td>
						</tr>
					</thead>
				</table>
			</div>
		</div>
		<template #footer>
			<div class="field">
				<label class="flex md:justify-content-start"
					><strong>Campos Requeridos: </strong> <span class="p-invalid">*</span></label
				>
			</div>
			<Button
				label="Cancelar"
				icon="pi pi-times"
				class="p-button-secondary p-button-lg"
				@click="ocultarModal"
				:disabled="!enviado ? false : true"
			/>
			<Button
				label="Guardar"
				icon="pi pi-check"
				class="p-button-primary p-button-lg"
				@click="guardarCajaCierre"
				:disabled="!enviado ? false : true"
				:loading="enviado"
			/>
		</template>
	</Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";

export default {
	emits: ["closeModal", "actualizarListado"],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		cajacierre: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			errors: {},
			enviado: false,
			mostrarModal: this.show,
			data_cajacierre: this.cajacierre,
		};
	},
	cajacierreService: null,
	computed: {
		saldo_caja() {
			return (
        parseFloat(this.data_cajacierre.saldo_inicial) +
				parseFloat(this.data_cajacierre.calculo_total_ventas_efectivo) +
				parseFloat(this.data_cajacierre.calculo_total_ingresos) -
        parseFloat(this.data_cajacierre.calculo_total_egresos) -
				parseFloat(this.data_cajacierre.monto_entregar)
			);
		},
		diferencia() {
			return (
        parseFloat(this.data_cajacierre.saldo_inicial) +
				parseFloat(this.data_cajacierre.calculo_total_ventas_efectivo) +
				parseFloat(this.data_cajacierre.calculo_total_ingresos) -
        parseFloat(this.data_cajacierre.calculo_total_egresos) -
				parseFloat(this.data_cajacierre.monto_entregar) -
        parseFloat(this.data_cajacierre.recuento_efectivo)
			);
		},
	},
	created() {
		this.cajacierreService = new CajaService();
	},
	methods: {
		convertirNumeroGermanicFormat(numero) {
			return new Intl.NumberFormat("de-DE", {
				minimumFractionDigits: 2,
			}).format(numero);
		},
		ocultarModal() {
			this.errors = {};
			this.mostrarModal = false;
			this.$emit("closeModal");
		},
		guardarCajaCierre() {
			this.errors = {};
			this.enviado = true;
			let tmp = {
				...this.data_cajacierre,
				estado: this.data_cajacierre.estado.value,
				total_ventas: this.data_cajacierre.calculo_total_ventas,
				diferencia: this.diferencia,
				saldo_caja: this.saldo_caja,
			};

			this.cajacierreService.updatedCaja(tmp).then((data) => {
				if (Object.prototype.hasOwnProperty.call(data, "errors")) {
					this.errors = data.errors;
				} else {
					this.$toast.add({
						severity: "success",
						summary: "Exito!",
						detail: data.mensaje,
						life: 3000,
					});
					this.$emit("actualizarListado");
					this.ocultarModal();
				}
				this.enviado = false;
			});
		},
	},
	watch: {
		show(val) {
			this.mostrarModal = val;
		},
		cajacierre(val) {
			val.diferencia = 0;
			val.recuento_efectivo = 0;
			val.monto_entregar = 0;
			val.fecha_cierre = new Date();
			this.data_cajacierre = val;
		},
    saldo_caja() {
      this.data_cajacierre.recuento_efectivo = this.saldo_caja;
    },
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
.p-fondo {
	background-color: #d8fadb;
}
.p-fondo2 {
	background-color: #f8d7da;
}
</style>
